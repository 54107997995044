<template>
	<div class="procedimentos-multiselect" >
		<label v-if="showLabel" class="form-label">Procedimento:</label>
			<CustomMultiSelect
				ref="multiselctProcedimentos"
				v-model="cd_procedimento"
				optionLabel="text"
				optionValue="value"
				dataKey="id"
				placeholder="- Selecione -"
				:filter="true"
				:showToggleAll="false"
				:showClear="!!cd_procedimento.length"
				:showSelected="!!cd_procedimento.length"
				:options="procedimentos"
				:loadingOptions="waiting"
				:loadingMore="waitingNextPage"
				:filterFunction="getProcedimentos"
				@change="val => $emit('change', val)"
				@input="$emit('input', cd_procedimento)"
				@clear="clear();$emit('clear')"
				@end-scroll="onEndScroll"
			>
				<template #option="props">
					<span><b>{{ props.option.text }}</b><br></span>
					<span v-if="!!props.option.value && props.option.ds_sinonimia">
						{{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }} ({{ props.option.ds_sinonimia }})
					</span>
					<span v-else-if="!!props.option.value">
						{{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }}
					</span>
				</template>
			</CustomMultiSelect>
		</div>
</template>

<script>
import CustomMultiSelect from "../CustomComponents/CustomMultiSelect.vue";
import { Procedimentos } from "../../middleware";
import axios from "axios";

export default {
	components: { CustomMultiSelect },
	props: {
		value: null,
		showLabel: Boolean,
	},
	created() { this.getProcedimentos(); },
	data() {
		return {
			cd_procedimento: [],
			procedimentos: [],
			waiting: false,
			waitingNextPage: false,
			lastPage: false,
			page: 1,
			per_page: 10,
			cancelToken: null,
		}
	},
	methods: {
		getProcedimentos(nm_procedimento, page) {
			if (nm_procedimento && nm_procedimento.trim().length < 2) return;

			nm_procedimento = nm_procedimento || "";
			this.page = page || 1;
			if (this.page === 1) this.lastPage = false;


			let params = {
				page: this.page,
				per_page: this.per_page,
				nm_procedimento: nm_procedimento
			}

			let waitingKey = (this.page === 1) ? "waiting" : "waitingNextPage";
			this[waitingKey] = true;

			if (this.cancelToken) this.cancelToken.cancel();
			this.cancelToken = axios.CancelToken.source();

			Procedimentos.findAllClean(params, this.cancelToken.token).then(response => {
				if (!response) return;
				if (response.status === 200) {
					let procedimentos  = response.data.map(procedimento => {
						return {
							value: procedimento.id,
							text: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`,
							...procedimento
						}
					})
					if (this.page === 1) {
						this.procedimentos = [];
						if (procedimentos.length) {
							this.procedimentos.push(...procedimentos);
						}
					} else {
						this.procedimentos.push(...procedimentos);
					}
					if (response.data.length < this.per_page) this.lastPage = true;
				}
				this[waitingKey] = false;
				this.cancelToken = null;
			})
		},
        clear() {
			this.cd_procedimento = []
			if (this.$refs.multiselctProcedimentos.filterValue) {
				this.$refs.multiselctProcedimentos.filterValue = '';
				// this.$refs.multiselctProcedimentos.checkedSelected = false;
				this.getProcedimentos();
			}
        },
		onEndScroll(filterValue) {
			if (!this.waiting && !this.waitingNextPage && !this.lastPage) {
				this.getProcedimentos(filterValue, ++this.page)
			}
		},
	}
}
</script>
