<template lang="pug">
.main-wrapper.paciente-salvar

        Dialog.dialogConsultarCEP(header='Consultar CEP' :modal='true' :visible.sync='dialogConsultarCEP' @hide='options.cep_municipiosEndereco = []')
            form(@submit.prevent='handleSubmitCEP()')
                .p-grid.p-fluid.p-align-end
                    .p-col-12
                        label.form-label Logradouro:
                        InputText(v-model='dialogConsultarCEP_data.logradouro' :disabled='dialogConsultarCEP_data.result.length > 0')

                    .p-col-6
                        label.form-label Estado:
                        Dropdown(v-model='dialogConsultarCEP_data.estado'
                            :disabled='dialogConsultarCEP_data.result.length > 0'
                            :options='options.ufs'
                            @change='dialogConsultarCEP_data.cidade = null; getMunicipiosEndereco(true)'
                            optionLabel='nm_estado'
                            optionValue='ds_sigla'
                            placeholder='Selecione'
                            :filter='options.ufs.length > 0')

                    .p-col-6
                        label.form-label Município:
                        Dropdown(v-model='dialogConsultarCEP_data.cidade'
                            :disabled='dialogConsultarCEP_data.result.length > 0'
                            :options='options.cep_municipiosEndereco'
                            optionLabel='text'
                            optionValue='text'
                            placeholder='Selecione'
                            :filter='options.cep_municipiosEndereco.length > 0')

                    .p-col-12(v-if='dialogConsultarCEP_data.result.length')
                        DataTable.datatable-cep(:value='dialogConsultarCEP_data.result' selectionMode='single' @row-select='onRowSelectCEP($event)')
                            Column(headerStyle='width: 30%;' bodyStyle='text-align:center' field='cep' header='CEP')
                            Column(headerStyle='width: 40%;' field='logradouro' header='Logradouro')
                            Column(headerStyle='width: 40%;' field='bairro' header='Bairro')
                        .ta-center.mt-3
                            Button.p-button-danger(label='Limpar' icon='jam jam-rubber' style='max-width:160px'
                                @click='dialogConsultarCEP_data.result = []')

                    .p-col-12.ta-center.mt-1(v-else)
                        ProgressSpinner.waitingCEPDialog(v-if='waitingCEPDialog' strokeWidth='6')
                        Button(v-else label='Consultar' icon='jam jam-search' style='max-width:160px')

        ProgressBar(v-show='waiting' mode="indeterminate")
        div(v-show='!waiting')
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: isAgente ? "agente-medclub" : "paciente" }'> {{ isAgente ? "Agentes" : "Pacientes" }}</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-show='waitingForm' mode="indeterminate")
                div(v-show='!waitingForm')
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } paciente`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nm_pessoa_fisica.$error }")
                                label.form-label Nome:
                                InputText(type='text' v-model='$v.model.nm_pessoa_fisica.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_pessoa_fisica.$error')
                                    .form-message--error(v-if="!$v.model.nm_pessoa_fisica.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_pessoa_fisica.required") <b>Nome</b> é obrigatório.

                            .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_cpf.$error }")
                                label.form-label CPF:
                                InputMask(type='tel' v-model='$v.model.nr_cpf.$model' mask='999.999.999-99' :placeholder="model.cd_pai ? '(Opcional)' : ''")
                                .feedback--errors(v-if='submitted && $v.model.nr_cpf.$error')
                                    .form-message--error(v-if="!$v.model.nr_cpf.validateCpf") <b>CPF</b> inválido.
                                    .form-message--error(v-if="!$v.model.nr_cpf.required") <b>CPF</b> é obrigatório.

                            .p-col-12.p-md-2
                                label.form-label Créditos MedClub:
                                InputText.ta-right(type='text' disabled readonly :value="model.nr_saldo" :style="{opacity: '1 !important'}")

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ie_sexo.$error }")
                                label.form-label Sexo:
                                SelectButton.input-sexo(type='text' v-model='$v.model.ie_sexo.$model' :options='options.ie_sexo' optionLabel='text' optionValue='value')
                                .feedback--errors(v-if='submitted && $v.model.ie_sexo.$error')
                                    .form-message--error(v-if="!$v.model.ie_sexo.required") <b>Sexo</b> é obrigatório.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.dt_nascimento.$error }")
                                label.form-label Data de nascimento:
                                InputMask(mask='99/99/9999' type='tel' v-model='$v.model.dt_nascimento.$model' placeholder='dd/mm/aaaa')
                                .feedback--errors(v-if='submitted && $v.model.dt_nascimento.$error')
                                    .form-message--error(v-if="!$v.model.dt_nascimento.dateValid") <b>Data de nascimento</b> é inválida.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                label.form-label Telefone:
                                InputMask(mask='(99) 99999999?9' type='tel' v-model='$v.model.nr_telefone.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                    .form-message--error(v-if="!$v.model.nr_telefone.validateFone") <b>Telefone</b> é inválido.

                            .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.ds_email.$error }")
                                label.form-label E-mail:
                                InputText(type='text' v-model='$v.model.ds_email.$model' placeholder='(Opcional)')
                                .feedback--errors(v-if='submitted && $v.model.ds_email.$error')
                                    .form-message--error(v-if="!$v.model.ds_email.email") <b>E-mail</b> é inválido.

                            .p-col-12.my-4(v-if='waitingResponsavel')
                                ProgressBar(mode='indeterminate')

                            .p-col-12(v-else)
                                form(v-if='!buscaResponsavel.result.length' @submit.prevent='getResponsavel()')
                                    label.form-label
                                        span Responsável:&nbsp;
                                        span.text-obs-responsavel(v-if='!model.cd_pai') (Deixar em branco se não houver)
                                    .p-inputgroup(v-if='!model.cd_pai')
                                        Dropdown.dropdown-buscaResponsavel(v-model='buscaResponsavel.field' :options='options.buscaResponsavel'
                                            optionLabel='text' optionValue='value' style='width:50%;text-align:right' @change='buscaResponsavel.value = null')
                                        InputMask(v-if="buscaResponsavel.field === 'nr_cpf'" type='tel' :disabled='!buscaResponsavel.field'
                                            v-model='buscaResponsavel.value' mask='999.999.999-99' placeholder='(Não possui)')
                                        InputText(v-else v-model='buscaResponsavel.value' placeholder='(Não possui)')
                                        Button(icon='jam jam-search' :disabled='!buscaResponsavel.value')
                                    .p-inputgroup(v-else)
                                        InputText.text-readonly(v-model='model.cd_pai.nm_pessoa_fisica_f' readonly)
                                        Button.p-button-danger(icon='jam jam-rubber' @click='buscaResponsavel.result = []; model.cd_pai = null')
                                div(v-else)
                                    label.form-label Responsável:
                                    .p-inputgroup
                                        Dropdown(v-model='model.cd_pai' :options='buscaResponsavel.result'
                                            optionLabel='nm_pessoa_fisica_f' optionValue='id')
                                        Button.p-button-danger(icon='jam jam-rubber' label='Limpar'
                                            @click='buscaResponsavel.result = []; model.cd_pai = null')

                            .p-col-3.mt-3
                                .p-field-checkbox
                                    label.form-label(style="margin:0") Este cliente gera comissão?
                                    Checkbox(v-model="$v.model.ie_permite_indicacao.$model" ref='checkIndica' :disabled="model.cd_pai" :binary="true" style="margin-left:10px")

                            .p-col-12
                                .p-grid.p-fluid.p-align-end.p-justify-end

                                    .p-col-12
                                        h4.text-box Endereço

                                    .p-col-12.p-md-2(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                        label.form-label CEP:
                                        InputMask(type='tel' v-model='$v.model.nr_cep.$model' mask='99999-999' @blur='searchCEP()' :disabled='waitingCEP')
                                        .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                            .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> inválido.
                                            .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.
                                        .waitingCEP(v-if='waitingCEP')
                                            ProgressSpinner(strokeWidth='6')
                                        a.link-naoseimeucep(v-if='!model.nr_cep' href='javascript:;' @click="naoSeiMeuCEP_open()") Não sei meu CEP

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                        label.form-label Estado:
                                        Dropdown(v-model='$v.model.cd_estado.$model' :options='options.ufs' @change='model.cd_cidade = null; getMunicipiosEndereco()'
                                            optionLabel='nm_estado' optionValue='id' placeholder='Selecione' :filter='options.ufs.length > 0' :disabled='waitingCEP')
                                        .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                            .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                        label.form-label Município:
                                        Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.municipiosEndereco' :filter='options.municipiosEndereco.length > 0'
                                            optionLabel='text' optionValue='value' placeholder='Selecione' :disabled='waitingCEP' v-tooltip.bottom="model.cd_estado == null ? 'Informe o estado antes' : ''")
                                        .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                            .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                                    .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                        label.form-label Logradouro:
                                        InputText(v-model='$v.model.nm_rua.$model' :disabled='waitingCEP')
                                        .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                            .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                                    .p-col-12.p-md-1(:class="{ 'form-group--error': submitted && $v.model.nr_numero.$error }")
                                        label.form-label Número:
                                        InputText(v-model='$v.model.nr_numero.$model' type='tel' :disabled='waitingCEP')
                                        .feedback--errors(v-if='submitted && $v.model.nr_numero.$error')
                                            .form-message--error(v-if="!$v.model.nr_numero.required") <b>Número</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                        label.form-label Bairro:
                                        InputText(type='text' v-model='$v.model.nm_bairro.$model' :disabled='waitingCEP')
                                        .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                            .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                                    .p-col-12.mt-3
                                        label.form-label Observações:
                                        Textarea.textarea-informacoes(v-model='$v.model.ds_observacao.$model' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12
                                .p-grid.p-fluid.p-align-end.p-justify-end

                                    .p-col-12
                                        h4.text-box Dados Bancários

                                    .p-col-12.p-md-3(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                        label.form-label Banco:
                                        Dropdown(v-model='$v.model.cd_banco.$model'
                                            :options='options.bancos'
                                            optionLabel='nm_banco'
                                            optionValue='id'
                                            placeholder='Selecione'
                                            :filter='options.bancos.length > 0')
                                        .feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
                                            .form-message--error(v-if="!$v.model.cd_banco.minLength") <b>Banco</b> inválido.

                                    .p-col-12.p-md-3(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.ie_tipo_conta.$error }")
                                        label.form-label Tipo de Conta:
                                        Dropdown(v-model='$v.model.ie_tipo_conta.$model'
                                            :options='options.tipo_contas'
                                            optionLabel='text'
                                            optionValue='value'
                                            placeholder='Selecione'
                                            :filter='options.bancos.length > 0')
                                        .feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
                                            .form-message--error(v-if="!$v.model.cd_banco.minLength") <b>Banco</b> inválido.

                                    .p-col-12.p-md-2(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_agencia.$error }")
                                        label.form-label Agência:
                                        InputText(type='text' v-model='$v.model.nr_agencia.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_agencia.$error')
                                            .form-message--error(v-if="!$v.model.nr_agencia.minLength") <b>Agência</b> inválido.

                                    .p-col-12.p-md-2(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_conta.$error }")
                                        label.form-label Conta:
                                        InputText(type='text' v-model='$v.model.nr_conta.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_conta.$error')
                                            .form-message--error(v-if="!$v.model.nr_conta.minLength") <b>Conta</b> inválido.

                                    .p-col-12.p-md-2(style='position:relative' :class="{ 'form-group--error': submitted && $v.model.nr_pix.$error }")
                                        label.form-label Pix:
                                        InputText(type='text' v-model='$v.model.nr_pix.$model')
                                        .feedback--errors(v-if='submitted && $v.model.nr_pix.$error')
                                            .form-message--error(v-if="!$v.model.nr_pix.minLength") <b>PIX</b> inválido.

                            .p-col-6.p-md-3.aux-2
                                p <b>Usuario original: </b> {{ model.nm_usuario_cri }}

                            .p-col-6.p-md-3.aux-2
                                p <b>Data da criação: </b> {{ model.dt_criado }}

                            .p-col-6.p-md-3.aux-2
                                p <b>Usuario edição: </b> {{ model.nm_usuario_edi }}

                            .p-col-6.p-md-3.aux-2
                                p <b>Data da atualização: </b> {{ model.dt_atualizado }}

                            .p-md-10
                            .p-col-12.p-md-2
                                Button(label='Salvar' icon='jam jam-save' type="submit")

                    .ta-right.mt-2(v-if='model.id')
                        ProgressSpinner.waitingCarteira(v-if='waitingCarteira' strokeWidth='4')
                        Button.p-button-warning(v-else label='Imprimir carteira' icon='jam jam-id-card' type='button' @click='imprimirCarteira()')

                    BuscaPaciente(ref='buscaPaciente' :hideSearchForm='true')

                    Panel.datatable.mt-2(v-if='dependentes.length' header='Dependentes')
                        DataTable(:value='dependentes')
                            Column(headerStyle='width: 40%;' bodyStyle='text-align: center;' field='nm_pessoa_fisica' header='Nome')
                            Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='nr_cpf' header='CPF')
                            Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='nr_telefone' header='Telefone')
                            Column(headerStyle='width: 20%;' header='Ações')
                                template(#body='props')
                                    .ta-center
                                        Button.p-button-raised.p-button-rounded.mr-1(
                                            v-tooltip.top="'Editar'"
                                            icon="jam jam-write"
                                            type='button'
                                            @click="editarDependente(props.data.id)"
                                        )

                    Panel.datatable.mt-2(v-if="+$route.params.id" header='Últimos agendamentos')
                        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' )
                            .p-grid.p-fluid.p-align-end

                                .p-col-12.p-md-2
                                    label.form-label Data:
                                    .p-inputgroup
                                        InputMask(
                                            mask='99/99/9999'
                                            placeholder='Data'
                                            :autoClear='false'
                                            @keydown.enter.prevent='applyFilters()'
                                            v-model='filters.dt_agenda'
                                        )
                                        Button(icon='jam jam-search' type='button' @click='applyFilters()')
                                .p-col-12.p-md-4
                                    label.form-label Nome do Estabelecimento:
                                    ProgressBar(v-if="options.estabelecimento_list.length == 0" mode="indeterminate")
                                    .p-inputgroup(v-else)
                                        MultiSelect(
                                            :options='options.estabelecimento_list'
                                            @change='applyFilters()'
                                            placeholder='TODOS'
                                            optionLabel='text'
                                            optionValue='value'
                                            dataKey='value'
                                            :filter='true'
                                            v-model='filters.cd_estabelecimento_list'
                                        )
                                .p-col-12.p-md-4
                                    label.form-label Especialidade:
                                    ProgressBar(v-if="options.especialidades.length == 0" mode="indeterminate")
                                    .p-inputgroup(v-else)
                                        MultiSelect(
                                            :options='options.especialidades'
                                            placeholder='TODOS'
                                            optionLabel='text'
                                            optionValue='value'
                                            dataKey='value'
                                            :filter='true'
                                            v-model='filters.cd_especialidades'
                                            @change='applyFilters()'
                                        )
                                .p-col-12.p-md-2
                                    label.form-label Situação:
                                    .p-inputgroup
                                        Dropdown(
                                            placeholder='Selecione...'
                                            v-model='filters.ie_status'
                                            :options='options.situacoes'
                                            optionLabel='situacao'
                                            optionValue='value'
                                            dataKey='value'
                                            @change='applyFilters()'
                                        )
                                .p-col-12.p-md-4
                                    label.form-label Especialista:
                                    .p-inputgroup
                                        InputText(
                                            placeholder='Nome do especialista'
                                            :autoClear='false'
                                            @keydown.enter.prevent='applyFilters()'
                                            @input='checkEmptyField("ds_especialista")'
                                            v-model='filters.ds_especialista'
                                        )
                                        Button(icon='jam jam-search' type='button' @click='applyFilters()')
                                .p-col-12.p-md-4
                                    label.form-label Procedimento:
                                    ProcedimentoMultiSelect(
                                        v-model="filters.id_procedimento"
                                        ref="procedimentosMultiselect"
                                        @change="applyFilters()")
                                    
                        TabView(style='margin-top:0', @tab-change='onTabChange')
                            TabPanel(v-for="tab in agendamentosTabs" :key="tab.ie_tipo_agenda" :header="tab.header"
                                v-bind:ieTipoAgenda="tab.ie_tipo_agenda" :active="filters.ie_tipo_agenda === tab.ie_tipo_agenda" :disabled='waitingAgendamento')
                                DataTable(:value="list" )
                                    template(#empty)
                                        ProgressBar(v-if='waitingAgendamento' mode="indeterminate")
                                        p.ta-center(v-else) Nenhum item encontrado
                                    
                                    //- div(v-show='!waitingAgendamento')
                                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='dt_agenda_f' header='Data')
                                    Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='hr_agenda_f' header='Horário')
                                    Column(headerStyle='width: 40%;' :header='tab.header')
                                        template(#body='props')
                                            div(v-if="props.data.ie_tipo_agenda === 'C'")
                                                b {{ props.data.ds_especialidade }}<br>
                                                span Dr(a). {{ props.data.ds_especialista }}
                                            div(v-else-if="props.data.ie_grupo != 'C'")
                                                span(v-if='props.data.nm_procedimento') {{props.data.nm_procedimento}}
                                                span(v-else v-for='i in props.data.cd_procedimentos' :key='i.id') &bull; {{ i.nm_procedimento }}
                                    Column(headerStyle='width: 15%;' header='Estabelecimento')
                                        template(#body='props')
                                            span {{ props.data.nm_estabelecimento}}
                                    Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
                                        template(#body='props')
                                            .cell(:class="{ ex: props.data.ie_status == 'EX', a: props.data.ie_status == 'A', l: props.data.ie_status == 'L' }")
                                                span {{ props.data.ie_status_f }}
                                    Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align:center')
                                        template(#body='props')
                                            span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                        Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .paciente-salvar {
        .datatable {
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
                &.l  { background-color: #e1f0f8; }
                &.r  { background-color: #f1e1f8; }
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled], .p-inputtext[readonly=readonly] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .waitingCEP {
            position: absolute;
            top: 34px;
            right: 20px;
            .p-progress-spinner {
                width: 20px;
                height: auto;
            }
        }
        .link-naoseimeucep {
            position: absolute;
            right: 8px;
            bottom: -14px;
            font-size: 13px;
            font-weight: 700;
        }
        .dialogConsultarCEP {
            width: 96%;
            max-width: 600px;
            .p-dropdown.p-disabled .p-inputtext {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
        }
        .datatable-cep {
            td { font-size: 13px; }
            max-height: 200px;
            overflow-y: auto;
        }
        .waitingCEPDialog, .waitingCarteira {
            width: 25px;
            height: auto;
        }
        .text-obs-responsavel {
            color: #888;
            font-size: 12px;
        }
        .dropdown-buscaResponsavel {
            .p-dropdown-label {
                font-weight: 700 !important;
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Paginator from 'primevue/paginator'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import TabView from 'primevue/tabview'
    import TabPanel from 'primevue/tabpanel'
    import Textarea from 'primevue/textarea'
    import Checkbox from 'primevue/checkbox'
    import BuscaPaciente from './BuscarPaciente'
    import wsConfigs from './../../middleware/configs'

    import { Paciente, Utils, Bancos, Estabelecimento, Agendamento } from './../../middleware'
    import { required, requiredIf, minLength, email } from 'vuelidate/lib/validators'
    import { validateCpf, validateFone, fixTelefoneFormat } from './../../utils'
    import moment from 'moment'
    import Methods from './Methods'
    import * as _ from 'lodash'
    import MultiSelect from 'primevue/multiselect'
    import ProcedimentoMultiSelect from "@/components/Procedimentos/ProcedimentoMultiSelect"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, DataTable, TabView, BuscaPaciente,
            TabPanel, InputMask, SelectButton, Dropdown, Tooltip, ProgressSpinner, Column, Textarea,
            Checkbox, Paginator, MultiSelect, ProcedimentoMultiSelect
        },
        directives: { tooltip: Tooltip },
        watch: {
            'model.cd_pai': function (val) {
                if(val){
                    this.model.ie_permite_indicacao = false
                }
            }
        },
        created () {
            this.isAgente = !!this.$route.query.agente
            this.model.ie_permite_indicacao = this.isAgente

            Utils.getUFs().then(response => {

                this.options.ufs.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                if (response.status == 200) this.options.ufs = response.data

                this.options.bancos.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
                Bancos.findAll().then(response => {
                    if (response.status == 200) {
                        this.options.bancos = response.data
                    }
                })

                if(+this.$route.params.id) {
                    this.getEstabelecimentos()
                    this.getEspecialidades()
                    this.getPaciente(+this.$route.params.id)
                    this.applyFilters()
                } else this.waiting = false
            })
        },
        data () {
            return {
                model: {
                    cd_pai: null,
                    nm_pessoa_fisica: '',
                    nr_cpf: '',
                    nr_telefone: '',
                    nr_saldo: 0,
                    dt_nascimento: '',
                    ie_sexo: null,
                    ds_email: '',
                    nm_bairro: '',
                    nm_rua: '',
                    nr_numero: '',
                    nr_cep: '',
                    cd_cidade: null,
                    cd_estado: null,
                    ie_permite_indicacao:false,
                    ie_tipo_conta:'',
                    cd_banco:'',
                    nr_agencia:'',
                    nr_conta:'',
                    nr_pix:'',
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                    dt_criado: '',
                    dt_atualizado: '',
                    ds_observacao: '',
                    ieTipoAgenda: 'C',
                },
                paginator: {
                    paginacao: true,
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                filters: {
                    ie_tipo_agenda: 'C',
                    dt_agenda: '',
                    cd_estabelecimento_list: null,
                    ds_especialidade: '',
                    ds_especialista: '',
                    nm_procedimento: '',
                    ie_status: null,
                    id_procedimento: null,
                    cd_especialidades: null,
                },
                options: {
                    ie_sexo: [
                        { text: 'Masculino', value: 'M' },
                        { text: 'Feminino', value: 'F' }
                    ],
                    buscaResponsavel: [
                        { text: 'Nome:', value: 'nm_pessoa_fisica' },
                        { text: 'CPF:', value: 'nr_cpf' }
                    ],
                    municipiosEndereco: [],
                    cep_municipiosEndereco: [],
                    ufs: [],
                    bancos: [],
                    tipo_contas: [
                        { text: 'Conta Corrente', value: 'CC' },
                        { text: 'Poupança', value: 'CP' },
                    ],
                    situacoes: [
                        {situacao: 'TODOS', value: null},
                        {situacao: 'Agendado', value: 'A'},
                        {situacao: 'Executado', value: 'EX'},
                        {situacao: 'Livre', value: 'L'},
                        {situacao: 'Cancelado', value: 'CA'}
                    ],
                    estabelecimento_list: [],
                    especialidades: []
                },
                buscaResponsavel: {
                    field: 'nm_pessoa_fisica',
                    value: null,
                    result: []
                },
                agendamentos: [],
                agendamentosTabs: [
                    { header: 'Consultas', ie_tipo_agenda: 'C' },
                    { header: 'Laboratório', ie_tipo_agenda: 'L' },
                    { header: 'Procedimentos', ie_tipo_agenda: 'P' }
                ],
                pacienteIndicador:false,
                isAgente: false,
                dependentes: [],
                waiting: true,
                waitingForm: false,
                waitingResponsavel: false,
                waitingCEP: false,
                waitingCEPDialog: false,
                waitingCarteira: false,
                waitingAgendamento: false,
                waitingEstabelecimento: false,
                waitingEspecialidade: false,
                dialogConsultarCEP: false,
                dialogConsultarCEP_data: {
                    logradouro: null,
                    cidade: null,
                    estado: null,
                    result: []
                },
                submitted: false,
                list: [],
                windowInnerWidth: window.innerWidth,
            }
        },
        validations () {
            let vm = this
            let v = {
                model: {
                    nm_pessoa_fisica: { required, minLength: minLength(2) },
                    nr_cpf: {
                        required: requiredIf(function(model) {
                            if (vm.pacienteIndicador)
                                return false
                            return !model.ie_permite_indicacao && model.cd_pai === null
                        }),
                        validateCpf: this.model.nr_cpf ? validateCpf : true
                    },
                    nr_telefone: { validateFone },
                    dt_nascimento: { dateValid: val => moment(val, 'DD/MM/YYYY').isValid() },
                    ds_email: { email },
                    ie_sexo: { required },
                    nr_cep: { required: requiredIf(function(model) { return model.cd_pai === null }), minLength: minLength(8) },
                    cd_cidade: { required: requiredIf(function(model) { return model.cd_pai === null }) },
                    cd_estado: { required: requiredIf(function(model) { return model.cd_pai === null }) },
                    nm_rua: { required: requiredIf(function(model) { return model.cd_pai === null }) },
                    nr_numero: { required: requiredIf(function(model) { return model.cd_pai === null }) },
                    nm_bairro: { required: requiredIf(function(model) { return model.cd_pai === null }) },
                    ds_observacao: {},
                    ie_permite_indicacao:{},
                    ie_tipo_conta:{},
                    cd_banco:{},
                    nr_agencia:{},
                    nr_conta:{},
                    nr_pix:{},
                }
            }
            return v
        },
        methods: {
            
            getList(id, params) {
                
                this.waitingAgendamento = true

                this.list = [];
                Paciente.findAgendamentos(id, params).then(response => {
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        let ie_status = { 'A': 'Agendado', 'L': 'Livre', 'EX': 'Executado' }
                        this.agendamentos = response.data.results.filter(agendamento => agendamento.ie_status !== 'R')
                        this.list = this.agendamentos
                        this.agendamentos.forEach(agendamento => {
                            agendamento.dt_agenda_f = moment(agendamento.dt_agenda).format('DD/MM/YYYY')
                            let aux = agendamento.hr_agenda.split(':')
                            agendamento.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
                            agendamento.ie_status_f = ie_status[agendamento.ie_status]
                        })
                    }
                    this.waitingAgendamento = false
                })
            },
            getPaciente(id) {
                Paciente.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => this.model[key] = response.data[key])
                        this.model.dt_nascimento = moment(this.model.dt_nascimento).format('DD/MM/YYYY')
                        this.model.id = id

                        this.model.dt_criado = moment(this.model.dt_criado).format('DD/MM/YYYY HH:mm')

                        if (this.model.dt_atualizado){
                            this.model.dt_atualizado = moment(this.model.dt_atualizado).format('DD/MM/YYYY HH:mm')
                        }

                        if (this.model.nr_cpf) this.model.nr_cpf = this.model.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

                        if (this.model.cd_pai) {
                            this.model.cd_pai.nr_cpf_f = this.model.cd_pai.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            this.model.cd_pai.nm_pessoa_fisica_f = `${ this.model.cd_pai.nm_pessoa_fisica } (${ this.model.cd_pai.nr_cpf_f })`
                        }

                        if (this.model.cd_cidade) {
                            this.model.cd_cidade = this.model.cd_cidade.id
                            this.model.cd_estado = response.data.cd_cidade.cd_estado.id
                        }

                        if (this.model.nr_telefone) this.model.nr_telefone = fixTelefoneFormat(this.model.nr_telefone)

                        this.model.nr_saldo = this.$root.formatPrice(this.model.nr_saldo)

                        this.dependentes = response.data.dependentes
                        this.dependentes.forEach(dependente => {
                            if (dependente.nr_cpf) dependente.nr_cpf = dependente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            if (dependente.nr_telefone) dependente.nr_telefone = fixTelefoneFormat(dependente.nr_telefone)
                        })

                        this.pacienteIndicador = this.model.ie_permite_indicacao
                        this.getMunicipiosEndereco()
                    }
                    this.waiting = false
                })
            },
            getMunicipiosEndereco (modal = false) {
                if (modal) Methods.getMunicipiosEnderecoModal(this)
                else Methods.getMunicipiosEndereco(this)
            },
            getEstabelecimentos(){
				this.waitingEstabelecimento = true
				Estabelecimento.findAllClean({order: 'nm_fantasia'}, {usarCidade: true}).then(response => {
					this.waitingEstabelecimento = false
					if (response.status === 200) {
						response.data.forEach(e => {
							this.options.estabelecimento_list.push({ text: e.nm_fantasia, value: e.id })
						})
						this.options.estabelecimento_list.unshift({ text: 'TODOS', value: null })
					}
				})
			},
            getEspecialidades() {
				this.waitingEspecialidade = true
				Agendamento.getEspecialidades().then(response => {
					this.waitingEspecialidade = false
                    if (response.status === 200) {
						response.data.forEach(espe => {
							this.options.especialidades.push({ text: espe.nm_especialidade, value: espe.id })
						})
						this.options.especialidades.unshift({ text: 'TODOS', value: null })
					}
				})
			},
            onTabChange (ev) {
				this.filters.ie_tipo_agenda = ev.tab.$attrs.ieTipoAgenda
                this.applyFilters()
			},
            checkEmptyField (field) {
                if (_.toString(this.filters[field])?.length < 1) this.applyFilters()
            },
            applyFilters(page) {
                this.paginator.page = page || 1;

                let params = {
                    paginacao: true,
                    page: this.paginator.page,
                    per_page: this.paginator.per_page
                };

                if (this.$route.query.tb != this.filters.ie_tipo_agenda || this.$route.query.pg != this.paginator.page)
					this.$router.replace( { query: { tb: this.filters.ie_tipo_agenda, pg: this.paginator.page } } )

                Object.keys(this.filters).forEach(key => {
                    if (this.filters[key] && !_.isArray(this.filters[key]) || _.isArray(this.filters[key]) && !_.isEmpty(this.filters[key])) {
                        if (key.substr(0, 3) === "dt_") {
                            if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
                            params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
                    } else params[key] = this.filters[key];
                    
                }
            });

            const id = +this.$route.params.id
            if(id) this.getList(id, params)
            else this.waiting = false

            },
            onPage (ev) {
                this.applyFilters(ev.page + 1)
            },
            imprimirCarteira () { Methods.imprimirCarteira(this) },
            naoSeiMeuCEP_open () { Methods.naoSeiMeuCEP_open(this) },
            onRowSelectCEP (ev) { Methods.onRowSelectCEP(this, ev) },
            getResponsavel () { Methods.getResponsavel(this) },
            handleSubmitCEP () { Methods.handleSubmitCEP(this) },
            handleSubmit () { Methods.handleSubmit(this) },
            searchCEP () { Methods.searchCEP(this) },
            editarDependente (id) {
                this.$refs.buscaPaciente.cd_pessoa_fisica = id
                this.$refs.buscaPaciente.dialogCadastro = true
            }
        }
    }
</script>
